import { render, staticRenderFns } from "./index.vue?vue&type=template&id=49d6c773&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=49d6c773&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d6c773",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Swiper: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/Swiper.vue').default,SwiperProduct: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/SwiperProduct.vue').default,ViewMoreBtn: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/ViewMoreBtn.vue').default})
